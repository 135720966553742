.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Application Css */
nav {
  /* font-family: 'Axiforma', sans-serif !important; */
  font-weight: 500;
  background-color: black !important;
  /* font-family: 'Alexandria', sans-serif !important;
font-family: 'Quicksand', sans-serif !important;  */
  /* font-family: 'Roboto', sans-serif !important; */
}

.navBtn {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
  border: none;
  background-color: #dce9f7;
  font-size: 15px
}

.dropdownCardBody {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
}

.dropdownNav:hover>.dropdownMenu {
  display: block;
  left: -150px;
}

.card {
  /* margin-bottom: 30px; */
  border: none;
  padding: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  /* -webkit-box-shadow: 0 0 37px rgb(8 21 66 / 5%); */
  /* box-shadow: 0 0 37px rgb(8 21 66 / 5%); */
  border: 1px solid #dee2e6 !important;
}

.dropdownCard {
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px dotted #dee2e6 !important;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  letter-spacing: 0px;
}

.rightNav {
  /* margin-top: 55px; */
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 2001;
  top: 57px;
  left: 70px;
  /* left: calc(100% - 330px); */
  /* background-color: #343a40; */
  overflow-x: scroll;
  box-shadow: 5px 10px 30px 0 rgb(0 0 0 / 15%);
  padding-bottom: 100px;
  transition: 0.5s;
}

#SectionSidenav {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

#closeNavBtn {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.custom_scroll {
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* .btn.nav-buttons {
  font-family: 'Sofia Sans Condensed', sans-serif;
} */

nav {
  background-color: #f4f7f6 !important;
  /* box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px; */
}

.leftSideNav {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
}

.SectionPropsWrapper {
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
  border-radius: 5px;
}

.SectionTitle {
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
}

.dropdownCard {
  width: 235px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px dotted #dee2e6 !important;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  letter-spacing: 0px;
}

.leftNav #closeNavBtn,
.rightNav #closeNavBtn {
  padding: 25px 8px 8px 15px;
  text-decoration: none;
  font-size: 16px;
  color: #0a0a0a;
  /* background-color: #bcddfb; */
  /* background: linear-gradient(-45deg, #b9b9b9, #eaeaea)!important; */
  text-transform: capitalize;
  /* margin-bottom: 15px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}

.btnTab {
  border: none !important;
  outline: none !important;
  background-color: #ffffff00;
  margin-top: 10px;
  padding: 5px 10px;
}

.btnactive {
  border-bottom: 3px solid #188bf6 !important;
  color: #2794eb;
}

.customCard {
  margin: 0 auto;
  width: 90%;
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px dotted #dee2e6 !important;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
  padding: 10px;
  cursor: pointer;
  letter-spacing: 0px;
}


.BtnElementOptions {
  margin: 5px;
  padding: 10px !important;
  width: 135px;
  border: 1px dashed #cdcdcd !important;
  border-radius: 3px !important;
  color: #607179 !important;
  /* box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px; */
}

.custom {
  font-size: 12px;
  /* font-weight: 700; */
  /* text-transform: uppercase; */
  letter-spacing: .025em;
  color: #4a5568;
  /* font-family: -webkit-pictograph; */
  /* font-family: 'Axiforma'; */
  font-weight: bolder;
}

.stepsListItem {
  display: flex;
  align-items: center;
  background-color: #bddcfd;
  border-radius: 5px;
  cursor: pointer
}

.settingItem {
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer
}

.ControlLabels,
.RowControlLabels,
.ColControlLabels,
.typeName,
typeNameRow,
.ElmControlLabels:hover {
  cursor: pointer;
}

.ControlLabels {
  font-size: 12px;
  color: white;
  padding: 5px 5px;
  position: absolute;
  top: 0px;
  width: auto;
  flex-wrap: wrap;
  right: 0px;
  overflow: auto;
  background-image: linear-gradient(rgba(255, 151, 0, 1), rgba(251, 75, 2, 1));
  ;
}

.ControlLabels::-webkit-scrollbar {
  background: #f0efef;
  visibility: visible;
  width: 4px;
}

ControlLabels::-webkit-scrollbar-thumb {
  background: #5ca8fc;
}

.typeName {
  font-size: 14px;
  color: white;
  padding: 5px 15px;
  position: absolute;
  border-radius: 10px;
  top: 5px;
  width: fit-content;
  left: 5px;
  background-image: linear-gradient(rgba(255, 151, 0, 1), rgba(251, 75, 2, 1));

}

.typeNameRow {
  font-size: 14px;
  color: white;
  padding: 5px 15px;
  position: absolute;
  border-radius: 10px;
  top: 5px;
  width: fit-content;
  left: 5px;
  background-image: linear-gradient(#20BF55, #01BAEF);

}

.typeNameColumn {
  font-size: 14px;
  color: white;
  padding: 5px 15px;
  position: absolute;
  border-radius: 10px;
  top: 5px;
  width: fit-content;
  right: 5px;
  background-image: linear-gradient(#09C6F9, #045DE9);

}

.typeNameElement {
  font-size: 14px;
  color: white;
  padding: 5px 15px;
  position: absolute;
  border-radius: 10px;
  top: 5px;
  width: fit-content;
  left: 5px;
  background-image: linear-gradient(#fa488c, #f807fc);
  z-index: 50;
}

.PlusSectionButton {
  display: block;
  z-index: 1;
  padding: 4px;
  color: white;
  position: absolute;
  border-radius: 100px;
  background-color: rgb(252 101 1);
  height: 30px;
  width: 30px;
  left: 50%;
  top: calc(100% - 10px);
  border: none;
  outline: none;
  bottom: 0px;
  z-index: 5;
}

.grow:hover {
  transform: scale(1.5);
}

.grow {
  transition: all .2s ease-in-out;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.active {
  display: block !important;
}

.disable {
  display: none !important;
}

.custom_scroll::-webkit-scrollbar {
  background: #f0efef;
  visibility: visible;
  width: 4px;
}

/* Handle */
#leftSideNav::-webkit-scrollbar-thumb {
  background: #5ca8fc;
}

/* Css adding Sidebar Css */

.addCssByDraging {
  justify-content: flex-start;
  padding: 0px 10px;
  flex-direction: column-reverse;
}

.addCssByDragingLabel {
  width: 100%;
  float: left;
  text-align: left;
}

.addCssByDragingInput {
  width: 75%;
  margin-right: 15px;
}

.addCssByDragingValue {
  width: 28%;
  border-radius: 5px;
}

.SectionPropsWrapper {
  background-color: white;
  border-radius: 20px;
}

.PropsTitle {
  padding-left: 1.5rem !important;
  padding-bottom: 1rem !important;
  border-bottom: 1px dashed #cdcdcd;
  text-align: start;
  padding-top: 5px;
  display: none;
}

.SectionProps {
  margin-top: 10px;
}

.tab-content {
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.SectionProps {
  margin-top: 15px;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 20px;
  padding-top: 20px;
}

.isSelected {
  border-bottom: 3px solid #188bf6 !important;
  color: #2794eb;
}

#menuItems {
  display: block !important;
}

.carouselOptionGroup {
  border: 2px solid #c3c3c3;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.form-control:not(.customInputElm):not(.bland) {
  /* height: 45px !important; */
  background-color: #edf2f7;
  transition: 0.3s;
}

.circle-picker {
  margin: auto !important;
}

.sketch-picker {
  margin: auto !important;
}

.swatches-picker {
  margin: auto !important;
}

.copyIDSpan:hover {
  background: rgba(24, 139, 246, .1);
  color: #188bf6;
  border-radius: 5px;
  cursor: pointer;
}

/* Row css */
.ControlLabelsRow {
  font-size: 12px;
  color: white;
  padding: 5px 5px;
  position: absolute;
  top: 0px;
  width: fit-content;
  right: 0px;
  background-image: linear-gradient(#20BF55, #01BAEF);
}

.PlusSectionButtonRow {
  display: block;
  z-index: 1;
  padding: 4px;
  color: white;
  position: absolute;
  border-radius: 100px;
  background-image: linear-gradient(#20BF55, #01BAEF);
  height: 22px;
  width: 22px;
  left: 50%;
  top: calc(100% - 10px);
  border: none;
  outline: none;
  bottom: 0px;
  z-index: 5;
}

.grow {
  cursor: pointer
}

.ControlLabelsColumn {
  font-size: 12px;
  color: white;
  padding: 5px 5px;
  position: absolute;
  top: 0px;
  width: fit-content;
  left: 0px;
  background-image: linear-gradient(#09C6F9, #045DE9);
}

.selectRowStyle {
  outline: #20BF55 solid 2px;
}

.widthsetColumn {
  z-index: 1050;
  position: absolute;
  border-radius: 100px;
  background-color: #045DE9;
  height: 12px;
  width: 12px;
  right: -6px;
  top: 50%;
  border: none;
}

.widthsetColumn:hover {
  cursor: e-resize;
}

.enableResizeColBtn {
  display: block !important;
}

#sectionsNavButton {
  border: none;
  background-color: #dce9f7;
  font-size: 15px;
}

#sectionsNavButton span {
  color: black;
  z-index: 5;
}

#sectionsNavButton::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

#sectionsNavButton:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#sectionsNavCard span {
  color: black;
  z-index: 5;
}

#sectionsNavCard::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


#sectionsNavCard:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

#sectionsNavManageBtn {
  color: #000;
  background-color: #bddcfd;
  letter-spacing: 0px;
  font-size: 15px;
  z-index: 5;
  width: 100%
}


#sectionsNavManageBtn::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


#sectionsNavManageBtn:hover::before {
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
}

.dropdownMenu {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg)
  }

  70% {
    transform: rotateX(20deg)
  }

  100% {
    transform: rotateX(0deg)
  }
}

@keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px)
  }

  80% {
    transform: translateZ(-10px) transLateY(0px)
  }

  100% {
    transform: translateZ(0px) transLateY(0px)
  }
}

#rowsNavButton {
  border: none;
  background-color: #dce9f7;
  font-size: 15px;
}

#rowsNavButton span {
  color: black;
  z-index: 5;
}

#rowsNavButton::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}

#rowsNavButton:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

#rowsNavCard span {
  color: black;
  z-index: 5;
}

#rowsNavCard::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


#rowsNavCard:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

#rowsNavManageBtn {
  color: #000;
  background-color: #bddcfd;
  letter-spacing: 0px;
  font-size: 15px;
  z-index: 5;
  width: 100%
}


#rowsNavManageBtn::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


#rowsNavManageBtn:hover::before {
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

#elementsNavButton {
  border: none;
  background-color: #dce9f7;
  font-size: 15px;
}

#elementsNavButton span {
  color: black;
  z-index: 5;
}

#elementsNavButton::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}

#elementsNavButton:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

#elementsNavCard span {
  color: black;
  z-index: 5;
}

#elementsNavCard::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


#elementsNavCard:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

#elementsNavManageBtn {
  color: #000;
  background-color: #bddcfd;
  letter-spacing: 0px;
  font-size: 15px;
  z-index: 5;
  width: 100%
}


#elementsNavManageBtn::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


#elementsNavManageBtn:hover::before {
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}


/* .desktopViewBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #fde9f3;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  z-index: -5;
} */

.desktopViewBtn:hover {
  background: #fde9f3;
  color: #e3169a;
  width: 100%;
  z-index: -5;
  border-radius: 0.375rem;
}

.desktopViewBtnActive {
  background: #fde9f3;
  color: #e3169a;
  width: 100%;
  border-radius: 0.375rem;
}


.mobileViewBtn:hover {
  background: #ffebcc;
  ;
  color: #ff9900;
  width: 100%;
  border-radius: 0.375rem;
}

.mobileViewBtnActive {
  background: #ffebcc;
  ;
  color: #ff9900;
  width: 100%;
  border-radius: 0.375rem;
}

.tabletViewBtn:hover {
  background: #e0f4eb;
  color: #00a87e;
  width: 100%;
  z-index: -5;
  border-radius: 0.375rem;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.tabViewBtnActive {
  background: #e0f4eb;
  color: #00a87e;
  width: 100%;
  border-radius: 0.375rem;
}


#columnsNavButton::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

#columnsNavButton:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.previewPageBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

.previewPageBtn:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.savePageBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

.savePageBtn:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/* #settingsBtn:hover {
  background-color: #dae7f5 !important;
} */

#settingsBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd !important;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

#settingsBtn:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#popupBtn:hover {
  background-color: #dae7f5 !important;
}

#popupBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd !important;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

#redoBtn {
  font-size: 25px;
}

#undoBtn {
  font-size: 25px;
}

#redoBtn:hover {
  background-color: #dae7f5 !important;
}

#redoBtn:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#redoBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd !important;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

#undoBtn:hover {
  background-color: #dae7f5 !important;
}

#undoBtn:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#undoBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd !important;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

#popupBtn:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#showCustomCodeBlocks:hover {
  background-color: #dae7f5 !important;
}

#showCustomCodeBlocks:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#showCustomCodeBlocks::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd !important;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

#AdminSave:hover {
  background-color: #dae7f5 !important;
}

#AdminSave:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  z-index: -5;
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#AdminSave::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd !important;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

.stepsBtn::before {
  transition: all 0.55s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #bddcfd;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* z-index: -5; */
}

.stepsBtn:hover::before {
  background-color: #bddcfd;
  width: 100%;
  color: black;
  /* z-index: -1; */
  border-radius: 0.375rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.stepsCard div {
  color: black;
  z-index: 5;
}

.stepsCard::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  /* background-color: #e3ecf7; */
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.stepsCard:hover::before {
  /* background-color: #e3ecf7; */
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
}

.stepsListItem::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  /* background-color: #e3ecf7; */
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.stepsListItem:hover::before {
  /* background-color: #e3ecf7; */
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
}

.settingItem1 span {
  color: black;
  z-index: 5;
}

.settingItem1::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem1:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.settingItem2 span {
  color: black;
  z-index: 5;
}

.settingItem2::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem2:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.settingItem3 span {
  color: black;
  z-index: 5;
}

.settingItem3::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem3:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.settingItem4 span {
  color: black;
  z-index: 5;
}

.settingItem4::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem4:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.settingItem5 span {
  color: black;
  z-index: 5;
}

.settingItem5::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem5:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.settingItem6 span {
  color: black;
  z-index: 5
}

.settingItem6::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem6:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.settingItem7 span {
  color: black;
  z-index: 5;
}

.settingItem7::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem7:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.settingItem8 span {
  color: black;
  z-index: 5;
}

.settingItem8::before {
  transition: all 0.85s ease;
  content: '';
  width: 0%;
  height: 100%;
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -5; */
}


.settingItem8:hover::before {
  background-color: #e3ecf7;
  border-bottom: 2px solid #03a9f4;
  width: 100%;
  color: black;
  /* z-index: -5; */
  border-radius: 0.375rem;
  ;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.SectionTitle {
  border-radius: 7px;
}

.elementAddBtnActive {
  display: block !important;
}

.elementActive {
  outline: #F907FC solid 2px;

}

.ElmControlLabels {
  font-size: 12px;
  color: white;
  padding: 5px 5px;
  position: absolute;
  top: 0px;
  width: fit-content;
  right: 0px;
  background-image: linear-gradient(#fa488c, #f807fc);
  z-index: 2001;
}

.ElmControlLabelsActive {
  display: none !important
}

.ql-toolbar {
  position: absolute !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  top: 110%;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
}

.imageFeatureToolbar{
  top: 105% !important;
}
.ql-editor ol, .ql-editor ul{
  padding: 0 !important;
}
.ql-container {
  height: 100% !important;
  position: relative !important;
  width: 100% !important;
  border: 0px !important;
}

.headingEditor .ql-toolbar .ql-formats:nth-child(2) {
  display: none !important;
}

.headingEditor .ql-toolbar .ql-formats:nth-child(3) {
  display: none !important;
}

.headingEditor .ql-toolbar .ql-formats:nth-child(4) {
  display: none !important;
}

.headingEditor .ql-toolbar .ql-formats:nth-child(5) {
  /* display: none !important; */
}

.headingEditor .ql-toolbar .ql-formats:nth-child(7) {
  display: none !important;
}

.headingEditor .ql-toolbar .ql-formats:nth-child(9) {
  display: none !important;
}

.ql-stroke {
  fill: none;
  stroke: #151212 !important;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #151212 !important;
}

.ql-picker-item {
  color: #151212 !important;
}

.ql-picker-label {
  color: #151212 !important;
}

.subheadingEditor .ql-toolbar .ql-formats:nth-child(2) {
  display: none !important;
}

.subheadingEditor .ql-toolbar .ql-formats:nth-child(3) {
  display: none !important;
}

.subheadingEditor .ql-toolbar .ql-formats:nth-child(4) {
  display: none !important;
}

.subheadingEditor .ql-toolbar .ql-formats:nth-child(5) {
  /* display: none !important; */
}

.subheadingEditor .ql-toolbar .ql-formats:nth-child(7) {
  display: none !important;
}

.subheadingEditor .ql-toolbar .ql-formats:nth-child(9) {
  display: none !important;
}

.paragraph .ql-toolbar .ql-formats:nth-child(2) {
  display: none !important;
}

.paragraph .ql-toolbar .ql-formats:nth-child(3) {
  display: none !important;
}

.paragraph .ql-toolbar .ql-formats:nth-child(4) {
  display: none !important;
}

.paragraph .ql-toolbar .ql-formats:nth-child(7) {
  display: none !important;
}

.paragraph .ql-toolbar .ql-formats:nth-child(9) {
  display: none !important;
}

.paragraph .ql-toolbar .ql-formats:nth-child(5) {
  display: none !important;
}

.paragraph .ql-toolbar .ql-formats:nth-child(6) {
  display: none !important;
}

.BulletListEditor .ql-toolbar .ql-formats:nth-child(2) {
  display: none !important;
}

.BulletListEditor .ql-toolbar .ql-formats:nth-child(4) {
  display: none !important;
}

.BulletListEditor .ql-toolbar .ql-formats:nth-child(6) {
  display: none !important;
}

.BulletListEditor .ql-toolbar .ql-formats:nth-child(9) {
  display: none !important;
}

.BulletListEditor .ql-toolbar .ql-formats:nth-child(7) .ql-video {
  display: none !important;
}

.BulletListEditor .ql-toolbar .ql-formats:nth-child(7) .ql-image {
  display: none !important;
}

.ql-picker-options {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset !important;
  border-radius: 5px;
}

.ql-toolbar.ql-snow .ql-picker-label {
  border-radius: 5px;
}

.ql-toolbar {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
  left: 0;
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg)
  }

  70% {
    transform: rotateX(20deg)
  }

  100% {
    transform: rotateX(0deg)
  }
}

@keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px)
  }

  80% {
    transform: translateZ(-10px) transLateY(0px)
  }

  100% {
    transform: translateZ(0px) transLateY(0px)
  }
}

.activeTextAlign {
  border: 2px solid #188bf6 !important;
  color: #188bf6 !important;
}

.iconPicker {
  float: left;
  width: 28px;
  height: 30px;
  font-size: 20px !important;
  margin: 0 12px 12px 0;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #dddddd;
  color: inherit;
}
.iconPicker:hover {
  background-color: #01BAEF !important;
}

.DeleteOptionBtn {
  background-color: #c82333;
  outline: none !important;
  box-shadow: none;
  color: #fff;
  position: absolute;
  left: 85%;
  margin: 0px;
  height: 25px;
  border-radius: 100%;
  width: 25px;
  padding: 0px;
  border: none;
  font-weight: bold;
}

.inputEditDivs,
.btnInputDivs {
  border: 1px dashed #cdcdcd;
  border-radius: 5px;
  padding: 5px;
  margin-top: 7px;
}

.cm-gutters {
  display: none !important;
}

.cm-content {
  height: 100%;
}

.cm-activeLine {
  text-align: left;
}

.ͼ3 .cm-activeLine {
  background-color: transparent !important;
}

.BtnFontOptions {
  margin: 5px;
  padding: 0px !important;
  width: 100%;
  height: 75px;
  border: 2px solid #eaeaea !important;
  border-radius: 3px !important;
  color: #607179 !important;
  background-color: #fff !important;
  font-size: 12px;
  word-break: break-word;
}

.cm-content {
  background-color: black !important;
  color: white !important;
}

.cm-editor {
  height: 350px;
  background-color: black !important;
  color: white !important;

}

.sideNavSection {
  display: flex;
  flex-direction: column;
  width: 70px;
  align-items: center;
  height: 100vh;
  position: fixed;
}

.sideNavIconPicker {
  float: left;
  width: 40px;
  height: 40px;
  font-size: 23px !important;
  margin: 24px 0px 0px 0;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

}

.secActive-1 {
  background: #ebf2ff !important;
  color: #116dff !important;

}

.secActive-2 {
  background: #d9d2ff !important;
  color: #6f56f9 !important;
}

.secActive-5 {
  background: #e0f4eb !important;
  color: #00a87e !important;
}

.secActive-4 {
  background: #ffebcc !important;
  ;
  color: #ff9900 !important;
}

.secActive-3 {
  background: #fde9f3 !important;
  color: #e3169a !important;
}

.sectionBox {
  width: 15%;
}

.SectionOptions {
  margin: 0px 0px 20px 0px;
  cursor: pointer;
  height: 85px;
  width: 100%;
}



.custom-btn {
  width: 130px;
  height: 70px;
  color: #fff;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

.sectionBtnCss {
  border: none;
  box-shadow: none;
  line-height: 42px;
  perspective: 230px;
}

.sectionBtnCss span {
  color: #116dff;
  background: rgb(235, 242, 255);
  background: linear-gradient(0deg, rgb(235, 242, 255) 0%, rgb(235, 242, 255) 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 42px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.sectionBtnCss span:nth-child(1) {
  box-shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.sectionBtnCss span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -22px;
}

.sectionBtnCss:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.sectionBtnCss:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.rowBtnCss {
  border: none;
  box-shadow: none;
  line-height: 42px;
  perspective: 230px;
}

.rowBtnCss span {
  color: #6f56f9;
  background: rgb(217, 210, 255);
  background: linear-gradient(0deg, rgb(217, 210, 255) 0%, rgb(217, 210, 255) 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 42px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.rowBtnCss span:nth-child(1) {
  box-shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.rowBtnCss span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -22px;
}

.rowBtnCss:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.rowBtnCss:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.elementBtn {
  border: none;
  box-shadow: none;
  line-height: 42px;
  perspective: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.elementBtn span {
  color: #e3169a;
  background: rgb(253, 233, 243);
  background: linear-gradient(0deg, rgb(253, 233, 243) 0%, rgb(253, 233, 243) 100%);
  display: block;
  position: absolute;
  width: 80px;
  height: 42px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  border-radius: 5px;
  margin: 0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.elementBtn span:nth-child(1) {
  box-shadow:
    -7px -7px 20px 0px #fff9,
    -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.elementBtn span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -22px;
}

.elementBtn:hover span:nth-child(1) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.elementBtn:hover span:nth-child(2) {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}


.custom-section-btn {
  color: black;
  border-radius: 5px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
}

.btn-10 {
  color: black;
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
}

.btn-10:after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  -webkit-transform: scale(.1);
  transform: scale(.1);
}

.btn-1:hover {
  color: #116dff;
  border: none;
  background: transparent;
}

.btn-1:hover:after {
  background: rgb(235, 242, 255);
  background: linear-gradient(0deg, rgb(185, 220, 255) 0%, rgb(235, 242, 255) 100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.btn-2:hover {
  color: #6f56f9;
  border: none;
  background: transparent;
}

.btn-2:hover:after {
  background: rgb(235, 242, 255);
  background: linear-gradient(0deg, rgb(188, 176, 255) 0%, rgb(217, 210, 255) 100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.btn-3:hover {
  color: #00a87e;
  border: none;
  background: transparent;
}

.btn-3:hover:after {
  background: rgb(224, 244, 235);
  background: linear-gradient(0deg, rgb(224, 244, 235) 0%, rgb(224, 244, 235) 100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.btn-4:hover {
  color: #ff9900;
  border: none;
  background: transparent;
}

.btn-4:hover:after {
  background: rgb(235, 242, 255);
  background: linear-gradient(0deg, rgb(253, 217, 162) 0%, rgb(255, 235, 204)100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.btn-5:hover {
  color: #e3169a;
  border: none;
  background: transparent;
}

.btn-5:hover:after {
  background: rgb(253, 233, 243);
  background: linear-gradient(0deg, rgb(255, 189, 222) 0%, rgb(253, 233, 243) 100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.elemWidth {
  width: 40% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}




.range-slider.grad {
  --progress-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) inset;
  --progress-flll-shadow: var(--progress-shadow);
  --fill-color: linear-gradient(to right, LightCyan, var(--primary-color));
  --thumb-shadow: 0 0 4px rgba(0, 0, 0, 0.3),
    -3px 9px 9px rgba(255, 255, 255, 0.33) inset,
    -1px 3px 2px rgba(255, 255, 255, 0.33) inset,
    0 0 0 99px var(--primary-color) inset;
}

.range-slider.grad input:hover {
  --thumb-transform: scale(1.2);
}

.range-slider.grad input:active {
  --thumb-shadow: inherit;
  --thumb-transform: scale(1);
}



.range-slider {
  --primary-color: #0366d6;
  --value-offset-y: var(--ticks-gap);
  --value-active-color: white;
  --value-background: transparent;
  --value-background-hover: var(--primary-color);
  --value-font: 700 12px/1 Arial;
  --fill-color: var(--primary-color);
  --progress-background: #eee;
  --progress-radius: 20px;
  --track-height: calc(var(--thumb-size) / 2);
  --min-max-font: 12px Arial;
  --min-max-opacity: 0.5;
  --min-max-x-offset: 10%;
  --thumb-size: 22px;
  --thumb-color: white;
  --thumb-shadow: 0 0 3px rgba(0, 0, 0, 0.4), 0 0 1px rgba(0, 0, 0, 0.5) inset,
    0 0 0 99px var(--thumb-color) inset;
  --thumb-shadow-active: 0 0 0 calc(var(--thumb-size) / 4) inset var(--thumb-color),
    0 0 0 99px var(--primary-color) inset, 0 0 3px rgba(0, 0, 0, 0.4);
  --thumb-shadow-hover: var(--thumb-shadow);
  --ticks-thickness: 1px;
  --ticks-height: 5px;
  --ticks-gap: var(--ticks-height,
      0);
  --ticks-color: silver;
  --step: 1;
  --ticks-count: Calc(var(--max) - var(--min)) / var(--step);
  --maxTicksAllowed: 30;
  --too-many-ticks: Min(1, Max(var(--ticks-count) - var(--maxTicksAllowed), 0));
  --x-step: Max(var(--step),
      var(--too-many-ticks) * (var(--max) - var(--min)));
  --tickInterval: 100/ ((var(--max) - var(--min)) / var(--step)) * var(--tickEvery, 1);
  --tickIntervalPerc: calc((100% - var(--thumb-size)) / ((var(--max) - var(--min)) / var(--x-step)) * var(--tickEvery, 1));
  --value-a: Clamp(var(--min),
      var(--value, 0),
      var(--max));
  --value-b: var(--value, 0);
  --text-value-a: var(--text-value, "");
  --completed-a: calc((var(--value-a) - var(--min)) / (var(--max) - var(--min)) * 100);
  --completed-b: calc((var(--value-b) - var(--min)) / (var(--max) - var(--min)) * 100);
  --ca: Min(var(--completed-a), var(--completed-b));
  --cb: Max(var(--completed-a), var(--completed-b));
  --thumbs-too-close: Clamp(-1,
      1000 * (Min(1, Max(var(--cb) - var(--ca) - 5, -1)) + 0.001),
      1);
  --thumb-close-to-min: Min(1, Max(var(--ca) - 2, 0));
  --thumb-close-to-max: Min(1, Max(98 - var(--cb), 0));
  display: inline-block;
  height: max(var(--track-height), var(--thumb-size));
  background: linear-gradient(to right, var(--ticks-color) var(--ticks-thickness), transparent 1px) repeat-x;
  background-size: var(--tickIntervalPerc) var(--ticks-height);
  background-position-x: calc(var(--thumb-size) / 2 - var(--ticks-thickness) / 2);
  background-position-y: var(--flip-y, bottom);
  padding-bottom: var(--flip-y, var(--ticks-gap));
  padding-top: calc(var(--flip-y) * var(--ticks-gap));
  position: relative;
  z-index: 1;
}

.range-slider[data-ticks-position=top] {
  --flip-y: 1;
}

/* .range-slider::before, .range-slider::after {
  --offset: calc(var(--thumb-size) / 2);
  content: counter(x);
  display: var(--show-min-max, block);
  font: var(--min-max-font);
  position: absolute;
  bottom: var(--flip-y, -2.5ch);
  top: calc(-2.5ch * var(--flip-y));
  opacity: clamp(0, var(--at-edge), var(--min-max-opacity));
  transform: translateX(calc(var(--min-max-x-offset) * var(--before, -1) * -1)) scale(var(--at-edge));
  pointer-events: none;
}
.range-slider::before {
  --before: 1;
  --at-edge: var(--thumb-close-to-min);
  counter-reset: x var(--min);
  left: var(--offset);
}
.range-slider::after {
  --at-edge: var(--thumb-close-to-max);
  counter-reset: x var(--max);
  right: var(--offset);
} */
.range-slider__values {
  position: relative;
  top: 50%;
  line-height: 0;
  text-align: justify;
  width: 100%;
  pointer-events: none;
  margin: 0 auto;
  z-index: 5;
}

.range-slider__values::after {
  content: "";
  width: 100%;
  display: inline-block;
  height: 0;
  background: red;
}

.range-slider__progress {
  --start-end: calc(var(--thumb-size) / 2);
  --clip-end: calc(100% - (var(--cb)) * 1%);
  --clip-start: calc(var(--ca) * 1%);
  --clip: inset(-20px var(--clip-end) -20px var(--clip-start));
  position: absolute;
  left: var(--start-end);
  right: var(--start-end);
  top: calc(var(--ticks-gap) * var(--flip-y, 0) + var(--thumb-size) / 2 - var(--track-height) / 2);
  height: calc(var(--track-height));
  background: var(--progress-background, #eee);
  pointer-events: none;
  z-index: -1;
  border-radius: var(--progress-radius);
}

.range-slider__progress::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  -webkit-clip-path: var(--clip);
  clip-path: var(--clip);
  top: 0;
  bottom: 0;
  background: var(--fill-color, black);
  box-shadow: var(--progress-flll-shadow);
  z-index: 1;
  border-radius: inherit;
}

.range-slider__progress::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: var(--progress-shadow);
  pointer-events: none;
  border-radius: inherit;
}

.range-slider>input {
  -webkit-appearance: none;
  width: 100%;
  height: var(--thumb-size);
  margin: 0;
  position: absolute;
  left: 0;
  top: calc(50% - Max(var(--track-height), var(--thumb-size)) / 2 + calc(var(--ticks-gap) / 2 * var(--flip-y, -1)));
  cursor: -webkit-grab;
  cursor: grab;
  outline: none;
  background: none;
}

.range-slider>input:not(:only-of-type) {
  pointer-events: none;
}

.range-slider>input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: var(--thumb-size);
  width: var(--thumb-size);
  transform: var(--thumb-transform);
  border-radius: var(--thumb-radius, 50%);
  background: var(--thumb-color);
  box-shadow: var(--thumb-shadow);
  border: none;
  pointer-events: auto;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.range-slider>input::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  height: var(--thumb-size);
  width: var(--thumb-size);
  transform: var(--thumb-transform);
  border-radius: var(--thumb-radius, 50%);
  background: var(--thumb-color);
  box-shadow: var(--thumb-shadow);
  border: none;
  pointer-events: auto;
  -moz-transition: 0.1s;
  transition: 0.1s;
}

.range-slider>input::-ms-thumb {
  appearance: none;
  height: var(--thumb-size);
  width: var(--thumb-size);
  transform: var(--thumb-transform);
  border-radius: var(--thumb-radius, 50%);
  background: var(--thumb-color);
  box-shadow: var(--thumb-shadow);
  border: none;
  pointer-events: auto;
  -ms-transition: 0.1s;
  transition: 0.1s;
}

.range-slider>input:hover {
  --thumb-shadow: var(--thumb-shadow-hover);
}

.range-slider>input:hover+output {
  --value-background: var(--value-background-hover);
  --y-offset: -5px;
  color: var(--value-active-color);
  box-shadow: 0 0 0 3px var(--value-background);
}

.range-slider>input:active {
  --thumb-shadow: var(--thumb-shadow-active);
  cursor: -webkit-grabbing;
  cursor: grabbing;
  z-index: 2;
}

.range-slider>input:active+output {
  transition: 0s;
}

.range-slider>input:nth-of-type(1) {
  --is-left-most: Clamp(0, (var(--value-a) - var(--value-b)) * 99999, 1);
}

.range-slider>input:nth-of-type(1)+output {
  --value: var(--value-a);
  --x-offset: calc(var(--completed-a) * -1%);
}

.range-slider>input:nth-of-type(1)+output:not(:only-of-type) {
  --flip: calc(var(--thumbs-too-close) * -1);
}

.range-slider>input:nth-of-type(1)+output::after {
  content: var(--prefix, "") var(--text-value-a) var(--suffix, "");
}

.range-slider>input:nth-of-type(2) {
  --is-left-most: Clamp(0, (var(--value-b) - var(--value-a)) * 99999, 1);
}

.range-slider>input:nth-of-type(2)+output {
  --value: var(--value-b);
}

.range-slider>input:only-of-type~.range-slider__progress {
  --clip-start: 0;
}

.range-slider>input+output {
  --flip: -1;
  --x-offset: calc(var(--completed-b) * -1%);
  --pos: calc(((var(--value) - var(--min)) / (var(--max) - var(--min))) * 100%);
  pointer-events: none;
  position: absolute;
  z-index: 5;
  background: var(--value-background);
  border-radius: 10px;
  padding: 2px 4px;
  left: var(--pos);
  transform: translate(var(--x-offset), calc(150% * var(--flip) - (var(--y-offset, 0px) + var(--value-offset-y)) * var(--flip)));
  transition: all 0.12s ease-out, left 0s;
}

.range-slider>input+output::after {
  content: var(--prefix, "") var(--text-value-b) var(--suffix, "");
  font: var(--value-font);
}

/* 
body {
  height: 100vh;
  display: grid;
  place-content: space-evenly;
  place-items: center;
  gap: 10%;
  padding: 0;
}
@media screen and (max-width: 500px) {
  body {
    padding-top: 2em;
    gap: 8%;
  }
}

a {
  position: fixed;
  top: 1em;
  left: 1em;
  display: inline-block;
  height: 2em;
}
@media screen and (max-width: 500px) {
  a {
    position: static;
    order: -1;
  }
}
a > img {
  display: inherit;
  height: 100%;
}

body > .range-slider,
label[dir=rtl] .range-slider {
  width: clamp(300px, 50vw, 800px);
  min-width: 200px;
} */


.optionbox {
  width: 100%;
  position: relative;
}

.optionbox select {
  background: #e4e4e4;
  color: #0d0d0d;
  border-radius: 10px;
  height: 46px;
  border: none;
  width: 100%;
  font-size: 20px;
  box-shadow: 0 5px 48px rgb(92, 90, 90);
  -webkit-appearance: button;
  outline: none;
}

.optionbox:before {
  content: '\f358';
  font-family: "Font Awesome 5 free";
  position: absolute;

  top: 0;
  right: 0;

  height: 46px;
  width: 40px;
  background: #E91E63;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 30px;
  pointer-events: none;


}

.optionbox:hover:before {

  background: #c72059;


}


.lds-roller {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 500;
  top: 40%;
  right: 43%;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.modalBodyFileMan:before {
  width: 100%;
  height: 100%;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
}

.confirmTab {
  position: absolute;
  top: 45%;
  z-index: 500;
  right: 36%;
  background: white;
  border-radius: 10px;
  height: 110px;
  padding: 20px;
}

.circle-picker {
  width: 100% !important;
  justify-content: center;
}

.circle-picker span div {
  margin: 10px !important;
}

.circle-picker span div span div {
  margin: 0px !important;
}

.swatches-picker {
  width: 100% !important;
}

.sketch-picker {
  width: 100% !important;
}

.input-group-append {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 5px;
}



.btn-7 {
  background: linear-gradient(0deg, rgba(255, 151, 0, 1) 0%, rgba(251, 75, 2, 1) 100%);
  line-height: 42px;
  padding: 0;
  border: none;
}

.btn-7 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0px 15px;
}

.btn-7:before,
.btn-7:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: rgba(251, 75, 2, 1);
  box-shadow:
    -7px -7px 20px 0px rgba(255, 255, 255, .9),
    -4px -4px 5px 0px rgba(255, 255, 255, .9),
    7px 7px 20px 0px rgba(0, 0, 0, .2),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
  transition: all 0.3s ease;
}

.btn-7:before {
  height: 0%;
  width: 2px;
}

.btn-7:after {
  width: 0%;
  height: 2px;
}

.btn-7:hover {
  color: rgba(251, 75, 2, 1);
  background: white;
}

.btn-7:hover:before {
  height: 100%;
}

.btn-7:hover:after {
  width: 100%;
}

.btn-7 span:before,
.btn-7 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: rgba(251, 75, 2, 1);
  box-shadow:
    -7px -7px 20px 0px rgba(255, 255, 255, .9),
    -4px -4px 5px 0px rgba(255, 255, 255, .9),
    7px 7px 20px 0px rgba(0, 0, 0, .2),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
  transition: all 0.3s ease;
}

.btn-7 span:before {
  width: 2px;
  height: 0%;
}

.btn-7 span:after {
  height: 2px;
  width: 0%;
}

.btn-7 span:hover:before {
  height: 100%;
}

.btn-7 span:hover:after {
  width: 100%;
}

.elementAddbtn {
  background: linear-gradient(0deg, #09C6F9 0%, #045DE9 100%);
  line-height: 42px;
  padding: 0;
  border: none;
  color: white;
}

.elementAddbtn span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0px 15px;
}

.elementAddbtn:before,
.elementAddbtn:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background-image: linear-gradient(#09C6F9, #045DE9);
  box-shadow:
    -7px -7px 20px 0px rgba(255, 255, 255, .9),
    -4px -4px 5px 0px rgba(255, 255, 255, .9),
    7px 7px 20px 0px rgba(0, 0, 0, .2),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
  transition: all 0.3s ease;
}

.elementAddbtn:before {
  height: 0%;
  width: 2px;
}

.elementAddbtn:after {
  width: 0%;
  height: 2px;
}

.elementAddbtn:hover {
  color: #045DE9;
  background: white;
}

.elementAddbtn:hover:before {
  height: 100%;
}

.elementAddbtn:hover:after {
  width: 100%;
}

.elementAddbtn span:before,
.elementAddbtn span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-image: linear-gradient(#09C6F9, #045DE9);
  box-shadow:
    -7px -7px 20px 0px rgba(255, 255, 255, .9),
    -4px -4px 5px 0px rgba(255, 255, 255, .9),
    7px 7px 20px 0px rgba(0, 0, 0, .2),
    4px 4px 5px 0px rgba(0, 0, 0, .3);
  transition: all 0.3s ease;
}

.elementAddbtn span:before {
  width: 2px;
  height: 0%;
}

.elementAddbtn span:after {
  height: 2px;
  width: 0%;
}

.elementAddbtn span:hover:before {
  height: 100%;
}

.elementAddbtn span:hover:after {
  width: 100%;
}

.addZoomIn {
  transform: scale(2);
}



.typeNameHover {
  display: block;
  visibility: visible;
  transform: translateY(-10px);
  opacity: 1;
  transition: 0.3s linear;
  animation: odsoky 1s ease-in-out infinite alternate;
}

@keyframes odsoky {
  0% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(1px);
  }
}




.come-in-right {
  transform: translateX(2000px);
  animation: come-in 1.8s ease forwards;
}

.come-in-left {
  transform: translateX(-2000px);
  animation: come-in 1.8s ease forwards;
}

@keyframes come-in {
  to {
    transform: translateX(0);
  }
}

.come-in-bottom {
  transform: translateY(1000px);
  animation: come-in-bottom 1.8s ease forwards;
}

.come-in-top {
  transform: translateY(-1000px);
  animation: come-in-bottom 1.8s ease forwards;
}

@keyframes come-in-bottom {
  to {
    transform: translateY(0);
  }
}





.sweepToRight {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.sweepToRight:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.sweepToRight:hover,
.sweepToRight:focus,
.sweepToRight:active {
  color: white;
}

.sweepToRight:hover:before,
.sweepToRight:focus:before,
.sweepToRight:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.sweepToLeft {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.sweepToLeft:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.sweepToLeft:hover,
.sweepToLeft:focus,
.sweepToLeft:active {
  color: white;
}

.sweepToLeft:hover:before,
.sweepToLeft:focus:before,
.sweepToLeft:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.sweepToBottom {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.sweepToBottom:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.sweepToBottom:hover,
.sweepToBottom:focus,
.sweepToBottom:active {
  color: white;
}

.sweepToBottom:hover:before,
.sweepToBottom:focus:before,
.sweepToBottom:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.sweepToTop {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.sweepToTop:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.sweepToTop:hover,
.sweepToTop:focus,
.sweepToTop:active {
  color: white;
}

.sweepToTop:hover:before,
.sweepToTop:focus:before,
.sweepToTop:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}



.copy-text {
  position: relative;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex !important;
  justify-content: space-between;
}

.copy-text input.text {
  padding: 3px;
  font-size: 14px;
  color: #555;
  border: none;
  outline: none;
  width: 75%;
}

.copy-text button {
  padding: 10px;
  background: #5784f5;
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

.copy-text button:active {
  background: #809ce2;
}

.copy-text button:before {
  content: "Copied";
  position: absolute;
  top: -45px;
  right: 0px;
  background: #5c81dc;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 15px;
  display: none;
}

.copy-text button:after {
  content: "";
  position: absolute;
  top: -20px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: #5c81dc;
  transform: rotate(45deg);
  display: none;
}

.copy-text.active button:before,
.copy-text.active button:after {
  display: flex;
}


.PlusIcon {
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.color-picker-button {
  text-shadow: 0px 0px white;
}

.selectBlock {
  display: flex;
}

.react-select_menu {
  z-index: 5000 !important;
  display: inline !important;
  overflow-x: hidden !important;
  background-color: #01BAEF !important;
  padding-left: 15px !important;
}
.react-select__menu-list{
  overflow-x: hidden !important;
  padding-left: 30px !important;
  
}
.react-select__option>li{
  display: inline !important;
  width: 33%;
  overflow-x: hidden !important;
}
.react-select__option:nth-child(2){
  display: none !important;
  width: 33%;
  overflow-x: hidden !important;
}
.react-select__option{
  display: inline !important;
  background-color: rgb(255, 255, 255) !important;
  overflow-x: hidden !important;
  padding-left: 35px !important;
}

/* .react-select_menu:first-child {
  z-index: 5000 !important;
  overflow-x: hidden !important;
  background-color: #01BAEF !important;
} */



#ExtraProperties {
  position: relative !important;
}

.modal.show .modal-dialog {
  top: 50px
}

.ImageItem {
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px, rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px, rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
}

.MuiCircularProgress-root {
  width: 80px !important;
  height: 80px !important;
}

.modal {
  z-index: 5000 !important;
}

.modal-backdrop {
  /* z-index: 499 !important; */
}

.modal::before {
  content: '';
  width: 100%;
  background-color: #000000;
  position: absolute;
  height: 100%;
  left: 0;
  opacity: 0.7;
}

.saveSectionContainer {
  position: relative;
  cursor: pointer;
  margin: 0 0 20px;
  width: 100%;
  height: 150px !important;
}

.Home {
  overflow: clip;
}

.Home::-webkit-scrollbar {
  background: #f0efef;
  visibility: visible;
  width: 4px;
}

.Home::-webkit-scrollbar-thumb {
  background: #5ca8fc;
}

html {
  overflow: hidden;
}

.proIcon {
  position: absolute;
  top: -15px;
  right: 5px;
  font-size: 15px;
  /* color: #f9ca24; */
  color: #fd7e14;
}

#upgradeModal{
  z-index: 5001 !important;
}

.ql-editor ul>li{
  display:block;
}

.owl-carousel .owl-stage{
  width: max-content !important
}

.slick-slide-img{
  max-height: 150px !important;
}

.marqueeItem{
  height:100%
}