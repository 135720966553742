.sectionMedium {
    max-width: 720px !important
}

body {
    background-size: cover;
}

.sectionFull {}

.sectionlarge {}

.sectionSmall {
    max-width: 540px !important
}

.allSections {
    background-color: rgba(255, 255, 255, 0);
    outline: rgba(251, 75, 2, 1) solid 2px;
    position: relative;
}

.Parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px;
}

.allRow {
    max-width: 1300px;
}

.allRowStyle {
    outline: #20BF55 solid 2px;
}

.allColumn {
    outline: rgb(215, 221, 233) dashed 1px;
}

.columnHover {
    outline: #009FFD solid 2px;
}

.selectColumnStyle {
    outline: #009FFD solid 2px;
}

.oneColumn {
    flex: 0 0 auto;
    width: 100%;
}

.twoColumn {
    flex: 0 0 auto;
    width: 49.5%;
}

.threeColumn {
    flex: 0 0 auto;
    width: 32.7%;
}

.fourColumn {
    flex: 0 0 auto;
    width: 24.2%;
}

.fiveColumn {
    flex: 0 0 auto;
    width: 19.2%;
}

.sixColumn {
    flex: 0 0 auto;
    width: 15.7%;
}

.sectionConstCss {
    position: relative;
}

.imageListItem {
    display: flex;
    justify-content: start;
    margin-bottom: 5px;
}

.imageListHolder .imageListItem>p {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px dotted;
    border-color: #ff7402;
    overflow: hidden;
}

.pulse {
    -webkit-animation: pulse 3s infinite;
    animation: pulse 3s infinite;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transition: .2s;
    transition: .2s;
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.07);
        transform: scale(1.07);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.buttonRocking {
    -webkit-animation: rocking 2s infinite;
    animation: rocking 2s infinite;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transition: .2s;
    transition: .2s;
}

@keyframes rocking {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(2deg);
        transform: rotate(2deg);
    }

    75% {
        -webkit-transform: rotate(-2deg);
        transform: rotate(-2deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.buttonBounce {
    -webkit-animation: bounce 1.5s infinite;
    animation: bounce 1.5s infinite;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transition: .2s;
    transition: .2s;
}

@keyframes bounce {
    15% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        box-shadow: 0 0 0 0 transparent;
    }

    35% {
        -webkit-transform: translateY(-35%);
        transform: translateY(-35%);
        box-shadow: 0 8px 5px -5px rgb(0 0 0 / 25%);
    }

    45% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        box-shadow: 0 0 0 0 transparent;
    }

    55% {
        -webkit-transform: translateY(-20%);
        transform: translateY(-20%);
        box-shadow: 0 5px 4px -4px rgb(0 0 0 / 25%);
    }

    70% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        box-shadow: 0 0 0 0 transparent;
    }

    80% {
        -webkit-transform: translateY(-10%);
        transform: translateY(-10%);
        box-shadow: 0 4px 3px -3px rgb(0 0 0 / 25%);
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        box-shadow: 0 0 0 0 transparent;
    }

    95% {
        -webkit-transform: translateY(-2%);
        transform: translateY(-2%);
        box-shadow: 0 2px 3px -3px rgb(0 0 0 / 25%);
    }

    99% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        box-shadow: 0 0 0 0 transparent;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        box-shadow: 0 0 0 0 transparent;
    }
}

.bounceHero {
    position: relative;
    -webkit-animation: bounceHero 3s infinite;
    animation: bounceHero 3s infinite;
}

@keyframes bounceHero {
    0% {
        top: 0;
    }

    50% {
        top: 25px;
    }

    100% {
        top: 0;
    }
}

.buttonElevate {
    -webkit-transition: .2s;
    transition: .2s;
    box-shadow: 0 0 0 0 transparent;
}

.buttonElevate:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    box-shadow: 0 8px 5px -5px rgb(0 0 0 / 25%);
}

.buttonWobble {
    -webkit-transition: .3s;
    transition: .3s;
}

.buttonWobble:hover {
    -webkit-animation: wobble .5s 1;
    animation: wobble .5s 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

.grow {
    transition: all .2s ease-in-out;
}

.grow:hover {
    transform: scale(1.5);
}

.growsmall {
    transition: all .2s ease-in-out;
}

.growsmall:hover {
    transform: scale(0.9);
}

.shareElement {
    color: #fff !important;
    margin-right: 5px !important;
    /* height: 30px!important; */
    border-radius: 0px !important;
    padding: 5px 20px !important;
    text-align: left;
    width: 20%;
}

.buttonPulseGlow {
    -webkit-animation: pulseGlow 2s infinite;
    animation: pulseGlow 2s infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@keyframes pulseGlow {
    0% {
        box-shadow: 0 0 0 0 hsl(0deg 0% 100% / 0%);
    }

    25% {
        box-shadow: 0 0 2.5px 1px hsl(0deg 0% 100% / 25%);
    }

    50% {
        box-shadow: 0 0 5px 2px hsl(0deg 0% 100% / 50%);
    }

    85% {
        box-shadow: 0 0 5px 5px hsl(0deg 0% 100% / 0%);
    }

    100% {
        box-shadow: 0 0 0 0 hsl(0deg 0% 100% / 0%);
    }
}

.buttonWobble {
    -webkit-transition: .3s;
    transition: .3s;
}

.buttonWobble:hover {
    -webkit-animation: wobble .5s 1;
    animation: wobble .5s 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@keyframes wobble {
    0% {
        -webkit-transform: skewX(0deg);
        transform: skewX(0deg);
    }

    25% {
        -webkit-transform: skewX(10deg);
        transform: skewX(10deg);
    }

    50% {
        -webkit-transform: skewX(0deg);
        transform: skewX(0deg);
    }

    75% {
        -webkit-transform: skewX(-10deg);
        transform: skewX(-10deg);
    }

    100% {
        -webkit-transform: skewX(0deg);
        transform: skewX(0deg);
    }
}

.surveyTabBtn {
    background-color: #cdcdcd;
    padding: 4px 10px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid #adadad;
    outline: none !important;
    margin: 0px 10px;
    font-size: inherit;
}

/* .ql-editor ul li {
    display: flex;
} */

.css-1nmdiq5-menu {
    display: flex !important;
    flex-direction: column !important;
}

.rowIndex {
    /* z-index: 1054; */
}

.countdownItems {
    display: block;
    padding: 10px;
    height: 110px;
    width: 100px;
    margin: 4px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 20%), inset 2px 4px 0 0 rgb(255 255 255 / 8%);
    font-size: 50px;
    font-weight: 900;
}

/* .modal-backdrop{
    z-index: 0 !important;
} */

.childIconSelect__option {
    display: inline !important;
    background-color: rgb(255, 255, 255) !important;
    overflow: hidden !important;
}

.childIconSelect__menu {
    /* display:inline !important;
    background-color: rgb(255, 255, 255) !important; */
    overflow: hidden !important;
    padding: 15px;
}

.globalHeaderFont {
    font-family: Gilroy !important;
    font-weight: normal;
}

.globalContentFont {
    font-family: Gilroy !important;
    font-weight: normal;
}


.owl-nav {
    display: none;
}

.owl-dots {
    display: none;
}

.owl-item {
    width: auto !important;
}

/* .owl-stage {
    width: 3600px !important
} */

.carouselMain {
    font-family: Arial;
    width: 500px;
    display: block;
    margin: 0 auto;
}

.allButton {
    display: inline-block !important;
}


.action {
    display: block;
    margin: 100px auto;
    width: 100%;
    text-align: center;
}

.action a {
    display: inline-block;
    padding: 5px 10px;
    background: #f30;
    color: #fff;
    text-decoration: none;
}

.action a:hover {
    background: #000;
}

.slick-slide img {
    margin: auto;
    width: 100%;
}

.thumbnail-slider-wrap {
    margin-top: 15px;
}

.thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
}

.thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 70%;
}

.slick-slide-div .slick-slide-image {
    width: 70%;
}

.slick-dotted .slick-list {
    margin-top: 15px;
    height: 85px;
}

#SideBarProps {
    font-size: 14px;
}

.modal-backdrop {
    z-index: auto !important;
    opacity: 0 !important;
}

.ql-container {
    height: auto !important;
    position: relative !important;
    width: 100% !important;
    border: 0px !important;
}

.ql-editor {
    height: auto !important;
}

#main-dustbin::-webkit-scrollbar {
    background: #f0efef;
    visibility: visible;
    width: 4px;
}

#main-dustbin::-webkit-scrollbar-thumb {
    background: #068cf0;
}

/* Media Query */
@media screen and (max-width:900px) {
    .allRow {
        flex-wrap: wrap !important;
    }
}